<template xmlns="http://www.w3.org/1999/html">
  <div class="content" >
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1d1d1d, black)"
    >

      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (9am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />
      <section class="text-center col-lg-8 col-sm-12">
        <HeadImage :image="mainImage" align="text-center" title="Tulum Clubs"
                   text-transform="uppercase"
                   clamp="true"
                   :color="primaryColor"
                   caption="Day Or Night Its Party Time"></HeadImage>

        <img v-lazy="lentes+'?fit=clamp&h=70&w=70'" height="70px" width="70px">
        <br>
        <p class="text-center centered  " style="max-width: 80%" >
          We list the <strong>Top Tulum </strong> Clubs you do not want to
          miss this
          <strong class="label label-info font-weight-700">{{
              getCurrentMonth
            }}</strong>
        </p>
        <p style="font-size: 0.9rem; margin-top: 40px">
          <a
            href="https://www.visitmexico.com/en/quintana-roo/tulum"
            target="_blank"
          >
            Tulum´s</a
          >
          peculiar musical culture can be found everywhere from
          <a
            href="https://zamnafestival.com/"
            rel="nofollow noopener"
            target="_blank"
          >Big festivals</a
          >, <a href="/">outdoor parties</a> and premium events at high-end
          restaurants & clubs. No matter the day nor time, there's always a DJ
          & dancing crowd somewhere in Tulum, waiting for you.
        </p>



        <section>
          <div class="card text-center" style="border: 2px solid black;margin-top: 30px">
            <h3
              class="tulum-party-subtitle"
              style="
                text-transform: uppercase;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
                font-weight: bold;
              "
            >
              <br/>
              Best clubs to dance & have fun
            </h3>
            <h5 style="margin-top: -30px">
              We take you into the Tulum's elite club scene
            </h5>
            <RsvpViaDialog
              legend="
                    <img src='https://imgix.cosmicjs.com/d38e0f20-9a39-11ee-b34c-99d1d89e42eb-aqua-eye.png' height='70px' width='70px' style='border-radius: 100px'>
                    <br><br><br>
                    <p>Information & Reservations <br> <small>The single source for all events & clubs needs</small></p>"
            ></RsvpViaDialog>
            <br/>
            <small style="color: aquamarine">
              We operate from 9AM - 10PM / Answer within 15 mins</small
            >
            <br/>
            <small>NO EXTRA FEE</small>
          </div>


        </section>

        <br/>
        <br/>

        <section class="row " style="margin-top: 30px;">
          <div class="centered " style="width: 70%">
            <router-link :to="{path: '/tulum-day-beach-clubs'}">
              <div class="card">
                <h4 class="section-header">BEACH CLUBS </h4>
                <h5>DAY OPTIONS</h5>

                <img v-lazy="beachImage+'?w=159&h=159&fit=clamp'"
                     style="border-radius: 0;margin-top: -10px;"
                     height="159px" width="159px" alt="beach clubs" class="centered">
                <div style="padding-left:20px;margin-top: -30px;background-color: rgba(0,0,0,0.6);border-radius: 0"
                     class="text-center centered card gradient">
                  <p>Check the list of the hottest clubs to spend your day</p>
                </div>
              </div>

            </router-link>
          </div>
        </section>


        <section class="row" style="margin-top: 10px">

          <div class="card text-center" style="border: 2px solid black">
            <p>Experience the Thrill: Tulum's Top Festive Clubs</p>
          </div>

          <h4 class="section-header" style="font-size: 1.4rem">NIGHT OPTIONS</h4>

          <card
            class="card text-center col-sm-12 col-lg-12"
            v-for="(row, idx) in venues"
            :key="idx"
            style="box-shadow: #000000 10px 10px 2px; padding: 40px"
          >
            <template slot="header">

              <router-link :to="{ name: 'Club', params: { slug: row.slug } }">



                <ResponsiveImage :image="row.metadata.main_image.imgix_url"
                                 class="card-animation-on-hover"></ResponsiveImage>

                <h4 class="tulum-party-title" style="margin-top: 20px;font-size: 1.7rem">
                  {{ row.title }}
                </h4>


                <p style="margin-top: -20px;font-size: 0.8rem">{{ clubsConfig[row.slug]["caption"] }}</p>
                <div class="card" v-if="idx === 0">

                  <br>
                  <p style="background-color: #373535;font-weight: bold">TODAY's RECOMMENDATION</p>

                </div>
                <hr class="brand-title-black" style="height: 1px"/>
              </router-link>
              <router-link
                :to="{ name: 'Club', params: { slug: row.slug } }"
                class="badge-pill"
                style="
              background-color: #23d5ab;
              margin-bottom: 5px;
              color: black !important;
            "
                v-if="incomingClubParties[row.title]"
              >{{ incomingClubParties[row.title].length }} Upcoming Events
              </router-link>
              <br v-if="incomingClubParties[row.title]"/>
              <div
                v-html="row.metadata.description"
                class="md-small-hide"
                style="color: #fff !important; margin-bottom: 10px"
              ></div>
              <!--          <ClubDetails :config="ClubTagsConfig[row.slug]"></ClubDetails>-->
            </template>

            <template slot="default">
              <router-link
                :to="{ name: 'Club', params: { slug: row.slug } }"
                class="btn font-weight-bold btn-primary"
                style="margin-top: 40px"
              >
                INFO & EVENTS
              </router-link>
            </template>
          </card>

        </section>
      </section>
      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu :links="links" :logo="this.clubsSpirt" ctaText="DROP US A LINE" top-section-name="PARTY TIME">
          <div class="row">
            <div class="col-sm-12 text-center">
              <p><strong>Feeling Lost?</strong></p>
              <hr>
              <p>
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
// import ClubFilter from "@/components/ClubFilter";
import clubsTableConfig from '../../common/clubsTableConfig';
import HeadImage from "@/components/HeadImage/HeadImage";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";
import clubsConfig from '../../common/clubsConfig';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );
const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'Clubs.vue',
  data: () => {
    return {
      showAll: false,
      showPalms: false,
      primaryColor: '#593f64',
      elegantDiver: 'https://imgix.cosmicjs.com/6f7d5cf0-b925-11ef-bee4-3bb1d3c55332-elegant-diver.png',
      showModal: true,
      ClubTagsConfig: {tags: []},
      clubsConfig: [{}],
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      clubsSpirt:
        'https://imgix.cosmicjs.com/7b95aff0-7b8f-11ec-8c2f-a300cf8a78dc-image.png',
      activityImg:
        'https://imgix.cosmicjs.com/3f6794f0-6cfe-11ee-a874-d142b6892265-activity.png',
      lentes: 'https://imgix.cosmicjs.com/62c260e0-b921-11ef-bee4-3bb1d3c55332-diver-lens-2.png',
      mainImage:
        'https://imgix.cosmicjs.com/a9432ac0-b923-11ef-bee4-3bb1d3c55332-papaya.jpg',
      beachImage: 'https://imgix.cosmicjs.com/2513afe0-f18e-11ec-8fb8-5d396858ac9b-IMG7503.jpg',
      questionClub: '',
      showReserve: false,
      questionType: 'info',
      pickOfTheDay: "https://imgix.cosmicjs.com/ed586fb0-b921-11ef-bee4-3bb1d3c55332-foco.png"
    };
  },
  metaInfo: () => ({
    title:
      "Tulum Top Clubs | Reservations · Events · Tickets · Clubs to Dance & Have Fun.",
    link: [{rel: 'canonical', href: 'https://www.tulum.party/tulum-clubs'}],
    meta: [
      {
        name: 'description',
        content:
          ' Information & Reservations for the best clubs in Tulum, Mexico.' +
          'Vagalume Tulum, Taboo Tulum, Mia Beach Club, Mia Tulum, Bonbonniere, Bagatelle, Confessions, Papaya Playa, Rosa Negra, Taboo, Gitano ',
      },
      {
        name: 'keywords',
        content:
          'tulum nightclubs, tulum beach clubs, tulum nightlife, tulum dj events, tulum party scene, tulum music venues, tulum dance clubs, tulum bars, tulum clubs calendar, tulum events calendar, tulum club reservations, tulum vip tables, tulum bottle service, tulum bachelorette party, tulum bachelor party, tulum vacation activities, tulum things to do at night',
      },
      {
        property: 'og:title',
        content: `Top Tulum Clubs to Dance & Have Fun. Tickets & Smart RSVP.`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'Tulum'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content: `Top Clubs Information & Reservations. Make a Reservation on Tulum's top Clubs. Party Ninjas & Quick Booking experts`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/3c6c5b60-96ea-11eb-b593-972a7dbc1054-papaya-playa-small.png',
      },
    ],
  }),
  components: {
    ResponsiveImage,
    SmartMenu,
    HeadImage,
    ClubDetails,
    clubsConfig,
    // ClubFilter,
    RsvpViaDialog,
    VueWhatsappWidget,
  },
  computed: {
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    incomingParties: {
      get() {
        return this.$store.state.incomingParties;
      },
    },
    incomingClubParties: {
      get() {
        return this.$store.state.incomingClubParties;
      },
    },
    venues: {
      get() {
        return this.$store.state.venues;
      },
    },
    tags: {
      get() {
        return [
          ...new Set(
            this.$store.state.venues
              .map((v) => v.metadata.tags.split('|'))
              .flat()
          ),
        ];
      },
    },
    categories: {
      get() {
        let h = {};
        this.$store.state.venues.forEach((venue) => {
          venue.metadata.tags.split('|').forEach((tag) => {
            if (h[tag]) {
              h[tag].push(venue.title);
            } else {
              h[tag] = [venue.title];
            }
          });
        });
        return h;
      },
      // return this.$store.state.venues.map(x => x.metadata.tags.split("|")).flat().filter((v,i,a) => a.indexOf(v) === i);
    },
    getCurrentMonth: {
      get() {
        let d = new Date();
        return (
          [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ][d.getMonth()] +
          ' ' +
          d.getFullYear()
        );
      },
    },
    sortedVenues: {
      get() {
        return [...this.$store.state.venues].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      },
    },
  },
  created() {
    // alert( Intl.DateTimeFormat().resolvedOptions().timeZone);
    this.$store.dispatch('getVenues', {start: 0, limit: 16});
    this.$store.dispatch('getParties');
    this.$store.dispatch('getLinks', {slug: 'tulum-clubs'});

    this.ClubTagsConfig = clubsTableConfig;
    this.clubsConfig = clubsConfig;

    window.history.scrollRestoration = 'manual';
  },
  updated() {
    // this.ClubTagsConfig = clubsTagsConfig;
    setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    paginate() {
      this.showAll = true;
      this.$store.dispatch('getVenues', {start: 12, limit: 20});
    },
    goToClub(event) {
      let path = '';
      if (event.target.value === 'dayClub') {
        path = '/tulum-day-beach-clubs';
      } else {
        path = '/tulum-clubs/' + event.target.value;
      }
      this.$router.push({
        path: path,
        replace: true,
      });

      this.$rollbar.info('QUICK CLUB: ' + path);
    },
  },
};
</script>
